@import '../styles/custom-properties.scss';
@import '../styles/custom-media.scss';

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0;
  background-color: var(--black);
  padding: 3rem 0 1rem;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      padding: 0;
    }
  }
}

.navBar {
  width: 80%;
  margin: 0 10%;
  background-color: var(--black);
  @media screen and (max-width: $breakpoint-extra-large) {
    width: 90%;
    margin: 0 5%;
  }
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      display: flex;
      flex-direction: row;
      align-items: space-between;
      text-align: right;
      width: 100%;
      margin: 0;
      padding: 1rem;
    }
  }
}

h3 {
  letter-spacing: 5px;
  font-weight: 900;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      letter-spacing: 0;
    }
  }
}
