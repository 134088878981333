@import '../styles/custom-media.scss';
@import '../styles/custom-properties.scss';

.footer {
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem 0;
  background-color: var(--black);
  color: var(--white);
  @media screen and (max-width: $breakpoint-medium) {
    font-size: smaller;
  }
}
