:root {
  /* Colors */
  --white: #ffffff;
  --minchew-blue: #32739f;
  --minchew-blue-dark-accent: #245272;
  --minchew-blue-overlay: rgba(50, 115, 159, 0.5);
  --dark-overlay: rgba(0, 0, 0, 0.6);
  --light-overlay: rgba(255, 255, 255, 0.6);
  --minchew-light-gray: #f1f1f1;
  --minchew-gray: #808080;
  --black: #343a40;

  /* Measurements */

  --max-width-mobile: 1251px;
}

@mixin lightButton {
  background-color: transparent;
  color: var(--minchew-blue-dark-accent);
  font-size: x-large;
  padding: 0.5rem 1rem;
  border: 2px solid var(--minchew-blue-dark-accent);
  border-radius: 1rem;
  &:hover {
    background-color: var(--minchew-blue);
    color: var(--black);
    transition: all 0.3s ease-in-out;
  }
}

@mixin eighty {
  width: 80%;
  margin: 0 10%;
}
