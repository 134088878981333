@import '../styles/custom-properties.scss';
@import '../styles/custom-media.scss';

.advertisers {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  font-size: calc(18px + 0.5vw);
  text-align: left;
  background-image: url('../assets/help.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-large) {
      background-attachment: scroll;
      background-size: contain;
      z-index: 3;
    }
  }
}
.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--dark-overlay);
  z-index: 1;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-large) {
      background-image: linear-gradient(
        var(--minchew-blue-dark-accent),
        var(--minchew-blue-dark-accent),
        var(--minchew-blue),
        var(--minchew-blue-dark-accent)
      );
    }
  }
}
.advertisers img {
  display: none;
  width: 100%;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-large) {
      display: inherit;
      z-index: 2;
    }
  }
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-large) {
      display: none;
    }
  }
}
.pageName {
  position: absolute;
  transform: rotate(-90deg) scaleX(-1);
  transform-origin: top left;
}
.content {
  color: white;
  font-size: calc(48px + 1vw);
  transform: scaleX(-1);
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      font-size: 9vw;
    }
  }
}

.advertisers p,
.advertisers ul {
  margin: 1rem 10%;
  color: var(--white);
  z-index: 2;
}
.advertisers p:nth-child(4) {
  margin-top: 40vh;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-large) {
      margin-top: 1rem;
    }
  }
}

.advertisers ul {
  list-style-type: square;
  list-style-position: inside;
}
