@import '../styles/custom-properties.scss';
@import '../styles/custom-media.scss';

.home {
  background-color: var(--white);
}
.home h3 {
  width: 100%;
  text-align: center;
  background-color: var(--black);
  color: var(--white);
  font-size: 2vw;
  margin-bottom: 0;
  padding-bottom: 1rem;
  @media screen and (max-width: $breakpoint-large) {
    display: none;
  }
}
.textScrollDiv {
  @include eighty;
  height: 44vw;
  max-height: 592px;
  max-width: 1076px;
  background-image: url('../assets/multimediaGal.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  // background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      width: 100%;
      height: 55vw;
      margin: 0;
    }
  }
}

.scrollWrapper {
  width: 43%;
  position: relative;
  overflow: hidden;
}
.scroller {
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0 2rem;
  text-align: left;
  /* apply animation */
  -moz-animation: scroll-up 36s linear infinite;
  -webkit-animation: scroll-up 36s linear infinite;
  animation: scroll-up 36s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
  @media screen and (max-width: $breakpoint-extra-large) {
    -moz-animation: scroll-up 60s linear infinite;
    -webkit-animation: scroll-up 60s linear infinite;
    animation: scroll-up 60s linear infinite;
  }
  @media screen and (max-width: $breakpoint-large) {
    -moz-animation: scroll-up 90s linear infinite;
    -webkit-animation: scroll-up 90s linear infinite;
    animation: scroll-up 90s linear infinite;
  }
  @media screen and (max-width: $breakpoint-medium) {
    -moz-animation: scroll-up 150s linear infinite;
    -webkit-animation: scroll-up 150s linear infinite;
    animation: scroll-up 150s linear infinite;
  }
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      display: none;
    }
  }
}

.maximized {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 4vw;
  letter-spacing: 5px;
  font-weight: 1200;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      letter-spacing: 2px;
    }
  }
}
.first {
  opacity: 0;
  animation: fade-out ease 9s;
  -webkit-animation: fade-out ease 9s;
  -moz-animation: fade-out ease 9s;
  -o-animation: fade-out ease 9s;
  -ms-animation: fade-out ease 9s;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      opacity: 1;
      animation: fade-in ease 10s;
      -webkit-animation: fade-in ease 10s;
      -moz-animation: fade-in ease 10s;
      -o-animation: fade-in ease 10s;
      -ms-animation: fade-in ease 10s;
    }
  }
}
.second {
  opacity: 0;
  animation: fade-out ease 6s;
  -webkit-animation: fade-out ease 6s;
  -moz-animation: fade-out ease 6s;
  -o-animation: fade-out ease 6s;
  -ms-animation: fade-out ease 6s;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      opacity: 1;
      animation: fade-in ease 15s;
      -webkit-animation: fade-in ease 15s;
      -moz-animation: fade-in ease 15s;
      -o-animation: fade-in ease 15s;
      -ms-animation: fade-in ease 15s;
    }
  }
}
.third {
  opacity: 0;
  animation: fade-out ease 2s;
  -webkit-animation: fade-out ease 2s;
  -moz-animation: fade-out ease 2s;
  -o-animation: fade-out ease 2s;
  -ms-animation: fade-out ease 2s;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      opacity: 1;
      animation: fade-in ease 20s;
      -webkit-animation: fade-in ease 20s;
      -moz-animation: fade-in ease 20s;
      -o-animation: fade-in ease 20s;
      -ms-animation: fade-in ease 20s;
    }
  }
}

.scrollWrapperMobile {
  width: 90%;
  height: 180px;
  margin: 2rem 5%;
  position: relative;
  overflow: hidden;
  display: none;
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      display: inherit;
    }
  }
}
.scrollerMobile {
  position: absolute;
  width: 100%;
  height: 300px;
  margin: 0;
  padding: 0 1rem;
  text-align: left;
  -moz-transform: translateY(300px);
  -webkit-transform: translateY(300px);
  transform: translateY(300px);
  /* apply animation */
  -moz-animation: scroll-up-mobile 60s linear infinite;
  -webkit-animation: scroll-up-mobile 60s linear infinite;
  animation: scroll-up-mobile 60s linear infinite;
}

@-moz-keyframes scroll-up {
  0% {
    -moz-transform: translateY(0%);
  }
  100% {
    -moz-transform: translateY(-200%);
  }
}
@-webkit-keyframes scroll-up {
  0% {
    -webkit-transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-200%);
  }
}
@keyframes scroll-up {
  0% {
    -moz-transform: translateY(0%); /* Browser bug fix */
    -webkit-transform: translateY(0%); /* Browser bug fix */
    transform: translateY(0%);
  }
  100% {
    -moz-transform: translateY(-200%); /* Browser bug fix */
    -webkit-transform: translateY(-200%); /* Browser bug fix */
    transform: translateY(-200%);
  }
}

.testimonial {
  margin-bottom: 0;
  font-style: italic;
}
.signature {
  font-weight: 600;
  margin-bottom: 3rem;
}

.welcome {
  @include eighty;
  text-align: left;
  font-size: 1.2rem;
}
.welcome h2 {
  margin: 3rem 0;
}

@-moz-keyframes scroll-up-mobile {
  0% {
    -moz-transform: translateY(100%);
  }
  100% {
    -moz-transform: translateY(-100%);
  }
}
@-webkit-keyframes scroll-up-mobile {
  0% {
    -webkit-transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
  }
}
@keyframes scroll-up-mobile {
  0% {
    -moz-transform: translateY(100%); /* Browser bug fix */
    -webkit-transform: translateY(100%); /* Browser bug fix */
    transform: translateY(100%);
  }
  100% {
    -moz-transform: translateY(-430%); /* Browser bug fix */
    -webkit-transform: translateY(-430%); /* Browser bug fix */
    transform: translateY(-430%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
///////////////////////FADE OUT

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
