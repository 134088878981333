@import '../styles/custom-properties.scss';
@import '../styles/custom-media.scss';

.contact {
  color: var(--minchew-blue);
  padding: 0 10%;
  display: flex;
  justify-content: center;
  background-color: var(--white);
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      padding: 0 5%;
    }
  }
}

.contactGrid {
  display: grid;
  grid-template-columns: 275px 4vw auto;
  grid-template-rows: 2vh auto auto 4vw auto;
  grid-template-areas:
    '    .    .     .'
    'address  .   form'
    'pic  .   form'
    '   pic   .   form'
    '   pic     .    form';
  text-align: left;
  @media screen and (max-width: $breakpoint-large) {
    grid-template-columns: auto 50% 50% auto;
    grid-template-rows: 2rem auto auto 2rem;
    grid-template-areas:
      '.   .     .  .'
      '. form  form .'
      '.address pic .'
      '.   .     .  .';
  }
}

.pic {
  grid-area: pic;
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.tagline {
  display: flex;
  font-size: 3vw;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: var(--minchew-blue-dark-accent);
  @media (pointer: coarse) {
    @media screen and (max-width: $breakpoint-handheld) {
      padding: 0 1rem;
      font-weight: 900;
    }
  }
}

.form {
  grid-area: form;
}
.address {
  grid-area: address;
}
.address a {
  color: var(--minchew-blue);
}

.nameEmailRow {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.nameEmailInput {
  width: 48%;
  margin-bottom: 1rem;
  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
  }
}
.textArea {
  width: 100%;
  margin-bottom: 1rem;
}
.contactButton {
  @include lightButton;
}
