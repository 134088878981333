@import './styles/custom-media.scss';

body,
html {
  margin: 0;
  font-family: 'Rubik', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  overflow-x: hidden;
  background-color: var(--black);
}
